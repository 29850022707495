import React from 'react';
import { Layout, Seo } from '../components';
import { Button, Col, Row } from 'antd';
import notFound from '../images/not-found.svg';
import styles from './404.module.css';

const NotFoundPage = () => (
  <Layout>
    <Seo title='404: Not found' />
    <div className="container">
      <div className={styles.notFound}>
        <h3><span className="turquoise">De pagina die je zocht, bestaat niet.</span></h3>
        <img src={notFound} alt="not found" />
        <Row justify="center">
          <Col lg={8} md={12}>
            <Button
              href="/"
              type="primary"
              block
            >
              Ga snel terug naar de homepage
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
